<template>
  <header
    v-if="config"
    class="pn-header"
  >
    <div class="container pn-header__wrap d-flex relative">
      <a
        :href="logoUrl"
        class="pn-header__logo"
        :style="`background: url(${
          basePath + config.provider.logo_partner
        }) 0 50% / contain no-repeat;`"
        @click.prevent="goHome"
      >
        ProviderLogo
      </a>

      <button
        data-test="header-city-change"
        class="pn-header__city d-flex align-center"
        @click="showDialogCity"
      >
        <span class="pn-header__city-icon d-flex">
          <img
            loading="lazy"
            src="@/assets/images/svg/pin-thin.svg"
            class=""
            alt="pin"
          >
        </span>
        <span>{{ currentCity?.short_name ? currentCity?.short_name + " " : ""
        }}{{ currentCity?.name }}</span>
      </button>
      <div class="pn-header__menu d-flex align-center">
        <nav class="pn-header__nav d-flex align-center">
          <LazyVMenu
            content-class="white--bg"
          >
            <template #activator="{ props }">
              <span
                v-bind="props"
                class="d-flex align-center pointer"
                data-test="header-services-button"
              >Услуги
                <img
                  :class="{ rotate: props['aria-expanded'] === 'true' }"
                  style="transition: transform 0.24s; width: 20px; height: 20px;"
                  loading="lazy"
                  src="@/assets/images/svg/arrow-expand.svg"
                  alt="arrow"
                >
              </span>
            </template>
            <LazyVList elevation="4">
              <LazyVListItem
                v-for="link in linkListAvailable"
                :key="link.id"
                data-test="header-pc-services-links"
                class="text-no-wrap pn-header__link"
                :to="`/${currentCity?.url}/${link.url}/`"
              >
                {{ link.name }}
              </LazyVListItem>
            </LazyVList>
          </LazyVMenu>
          <a
            v-if="!segmentationActive"
            href="/contacts/"
            @click.prevent="goTo(`/contacts/`)"
          >Контакты</a>
          <!--          <a :href="'/about/'" @click.prevent="$router.push({path: '/about/'})">О компании</a> -->
        </nav>
        <LazyVMenu
          v-model:model-value="ddPhone"
          location="bottom end"
          offset="-52"
          @update:model-value="getPhones"
        >
          <template #activator="{ props }">
            <span
              v-bind="props"
              class="pointer pn-header__phone pn-header__phone--pc d-flex align-center justify-center title-4"
            >
              Позвонить
              <span
                class="mask-icon mask-size-lg mask-phone-calling"
                style="margin-left: 4px;"
              />
            </span>
          </template>
          <div class="pn-header__phone-wrap d-flex flex-column relative white--bg">
            <template v-if="!segmentationActive && !hideNumber">
              <p>Интересует подключение?</p>
              <a
                class="secondary-dark--text fwm d-flex align-center"
                :href="'tel:' + callNumber.call_number"
                @click.prevent="
                  callClick(callNumber.call_number, 'SHOW_CONNECT')
                "
              ><span
                class="mask-icon mask-size-lg mask-phone-thin"
                style="margin-right: 4px;"
              />{{ callNumber.display_number }}</a>
              <span class="gray-dark--text">Звонок бесплатный по РФ</span>
            </template>
            <p>Нужна техподдержка?</p>
            <a
              v-if="phone"
              class="secondary-dark--text fwm d-flex align-center"
              :href="'tel:' + phone.call_number"
              @click.prevent="callClick(phone.call_number, 'SHOW_SUPPORT')"
            ><span
              class="mask-icon mask-size-lg mask-phone-thin"
              style="margin-right: 4px;"
            />{{ phone.display_number }}</a>
            <span class="gray-dark--text">Звонок бесплатный по РФ</span>
            <button
              class="gray-darker--bg d-flex align-center justify-center"
              @click="closeDD"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/close-thin.svg"
                class="d-flex"
                alt="close"
                style="width: 24px; height: 24px; opacity: .7"
              >
            </button>
          </div>
        </LazyVMenu>
        <button
          :class="{ 'pn-header__burger--active': menuOpenned }"
          class="pn-header__burger d-flex align-center justify-center"
          @click="toggleMenu"
        >
          <span />
        </button>
        <button
          class="pn-header__phone pn-header__phone--mb d-flex align-center justify-center"
          @click="showPhoneModal"
        >
          <span class="mask-icon mask-size-lg mask-phone-calling" />
        </button>
      </div>
    </div>

    <LazyVDialog
      v-model="phoneModal"
      :fullscreen="!disabledPortal"
      :width="disabledPortal ? 'auto' : ''"
      content-class="transparent"
      @after-leave="stopGetCall"
    >
      <div
        ref="parent"
        class="pn-header__phone-wrap d-flex flex-column relative white--bg"
      >
        <div
          class="pn-header__phone-mclose d-flex align-center justify-center"
          @click="phoneModal = false"
        >
          <img
            loading="lazy"
            src="@/assets/images/svg/close.svg"
            alt="close"
          >
        </div>
        <template v-if="!segmentationActive && !hideNumber">
          <p>Интересует подключение?</p>
          <a
            class="secondary-dark--text fwm d-flex align-center"
            :href="'tel:' + callNumber.call_number"
            @click.prevent="callClick(callNumber.call_number, 'SHOW_CONNECT')"
          >{{ callNumber.display_number }}</a>
          <span class="gray-dark--text">Звонок бесплатный по РФ</span>
        </template>
        <p>Нужна техподдержка?</p>
        <a
          v-if="phone"
          class="secondary-dark--text fwm d-flex align-center"
          :href="'tel:' + phone.call_number"
          @click.prevent="callClick(phone.call_number, 'SHOW_SUPPORT')"
        >{{ phone.display_number }}</a>
        <span class="gray-dark--text">Звонок бесплатный по РФ</span>
      </div>
    </LazyVDialog>
    <LazyVMobileDrawer
      v-model="menuOpenned"
      location="top"
      :scrim="false"
      class="pn-header__drawer"
      style="z-index: 1 !important"
      :touchless="true"
    >
      <div class=" d-flex flex-column">
        <p class="fwm">
          Услуги
        </p>
        <a
          v-for="link in linkListAvailable"
          :key="link.id"
          :href="`/${currentCity?.url}/${link.url}/`"
          class="text-no-wrap pn-header__link"
          @click.prevent="goTo(`/${currentCity?.url}/${link.url}/`)"
        >
          {{ link.name }}
        </a>
        <a
          v-if="!segmentationActive"
          class="fwm"
          href="/contacts/"
          @click.prevent="goTo(`/contacts/`)"
        >Контакты</a>
      </div>
    </LazyVMobileDrawer>
  </header>
</template>

<script setup lang="ts">
import { basePath } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { getCallNumber } from '~/composible/getCallNumber'
import { createInteraction } from '~/composible/createInteraction'

const emit = defineEmits(['close'])

const ctx = useNuxtApp()
const $gtm = useGtm()
const cityStore = useCities()
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const houseHash = computed(() => cityStore.getHouseHash)
const currentCity = computed(() => cityStore.getCity)
const config = computed(() => mainStore.getDomainConfig)
const phone = computed(() => mainStore.getCallNumber?.support)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const disabledPortal = computed(() => mainStore.disabledPortal)
const houseUrl = computed(() => cityStore.getHouseUrl)
const logoUrl = computed(() => `/${currentCity.value?.url}/`)
const $router = useRouter()
const { callNumber, getPhoneNumber, stopGetCall } = getCallNumber()
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const fetching = ref(false)
const menuOpenned = ref(false)
const phoneModal = ref(false)
const parent = ref()
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const hideNumber = computed(() => mainStore.getHideNumber)
const segmentation = computed(() => mainStore.getSegmentation)
const ddown = ref()
const ddPhone = ref(false)
const goHome = () => {
  $router.push(`/${currentCity.value?.url}/`)
  emit('close')
}
const toggleMenu = () => {
  menuOpenned.value = !menuOpenned.value
  if (menuOpenned.value && window && !import.meta.server) {
    window.document.getElementsByTagName('html')[0]?.classList.add('hidden')
  }
  else if (window) {
    window.document.getElementsByTagName('html')[0].classList.remove('hidden')
  }
}

const { cI } = createInteraction()

const callClick = async (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    mainStore.$patch({
      interactionsSended: true,
    })
    cI(
      true,
      undefined,
      { provider_id: config.value?.provider?.id },
      { request_type: type },
      undefined,
      undefined,
    )
  }
  if (type === 'SHOW_SUPPORT') {
    mainStore.$patch({
      oldClient: segmentation.value,
    })
    oldClientCookie.value = segmentation.value
    await $gtm.trackEvent({
      event: 'trackEvent',
      category: 'phonelink',
      action: 'support',
    })
    await $gtm.trackEvent({
      event: 'trackEvent',
      category: 'segmentation',
      action: 'blocked',
    })
  }
  else {
    $gtm.trackEvent({
      event: 'trackEvent',
      category: 'phonelink',
      action: 'connect',
    })
  }
}

const linkList = computed(() => tariffsStore.getLinkList)
const linkListAvailable = computed(() => {
  return houseUrl.value && availableLinks.value.length
    ? linkList.value.filter(el => availableLinks.value.includes(el.id))
    : linkList.value.filter(el =>
      availableCityLinks.value.includes(el.id),
    )
})

const showDialogCity = () => {
  ctx.$event('cityDialog', { redirect: true, label: 'header' })
}

const showPhoneModal = async () => {
  phoneModal.value = true
  await getPhoneNumber(false)
}

const getPhones = (val) => {
  if (val) getPhoneNumber(false)
  else stopGetCall()
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const client = ctx._apolloClients.default
const getLinks = async (city: boolean) => {
  const res = (
    await client.query({
      query: gql`
        query getPartnersAvailableService(
          $input: LocationInfoInput!
          $tariffsInput2: BaseTariffsInput
        ) {
          location {
            locationTerms(input: $input) {
              tariffs(input: $tariffsInput2) {
                agg {
                  allAvailableServiceGroups {
                    id
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        input: {
          hash:
            houseUrl.value && !city
              ? houseHash.value || undefined
              : undefined,
          url: houseUrl.value && !city ? houseUrl.value : undefined,
          addrObjFiasId: currentCity.value?.fias_id,
          strictTechs: true,
        },
        tariffsInput2: {
          providerSlugs: config.value?.provider?.slug,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })
  ).data.location.locationTerms.tariffs.agg.allAvailableServiceGroups

  return res
}

const availableLinks = computed(
  () => data.value?.availableLinks?.map(el => +el.id) || [],
)
const availableCityLinks = computed(
  () => data.value?.availableCityLinks?.map(el => +el.id) || [],
)

let errorStatus
let errorMessage
const { data, error } = await useAsyncData('pn-header' + (currentCity.value?.fias_id || '') + (houseUrl.value || ''), async () => {
  let availableLinks
  let availableCityLinks

  const availableLinksFetch = async () => {
    availableLinks = await getLinks(false)
  }
  const availableCityLinksFetch = async () => {
    availableCityLinks = await getLinks(true)
  }

  await Promise.all([availableLinksFetch(), availableCityLinksFetch()])

  return {
    availableLinks,
    availableCityLinks,
  }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}

const closeDD = () => {
  ddPhone.value = false
  stopGetCall()
}

const goTo = (link) => {
  $router.push({ path: link })
  menuOpenned.value = false
  window.document.getElementsByTagName('html')[0].classList.remove('hidden')
  if (ddown.value?.elDropdown) ddown.value.elDropdown.handleClose()
}

watch(
  () => currentCity.value?.fias_id,
  async () => {
    if (!fetching.value) {
      data.value.availableCityLinks = await getLinks(true)
    }
  },
)
watch(
  () => houseUrl.value,
  async () => {
    if (!fetching.value) {
      data.value.availableLinks = await getLinks(false)
    }
  },
)
</script>

<style scoped lang="scss">
.pn-header {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  .mask-phone-thin {
    mask-image: url('@/assets/images/svg/phone-thin.svg');
  }
  .mask-phone-calling {
    mask-image: url('@/assets/images/svg/phone-calling.svg');
  }
  &__wrap {
    background: color(white);
    align-items: center;
    height: 80px;
    z-index: 2;
    @media (max-width: getBreakpoint(tablet)) {
      height: 70px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 65px;
    }
  }

  &__logo {
    display: inline-flex;
    font-size: 0;
    height: 60px;
    width: 240px;
    object-fit: contain;
    object-position: left;
    @media (max-width: getBreakpoint(tablet)) {
      width: 172px;
      height: 43px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 140px;
      height: 35px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 100px;
      height: 25px;
    }
  }

  &__city {
    margin-left: 40px;
    color: color(black);
    transition: opacity 0.24s;
    opacity: .7;
    &-icon {
      margin-right: 8px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    &:hover {
      opacity: 1;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: auto;
      padding: 0;
      &-icon {
        width: 44px;
        height: 44px;
        background: color(gray-p);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        img {
          width: 28px;
          height: 28px;
        }
      }
      span:not(.pn-header__city-icon) {
        display: none;
      }
    }
  }

  &__menu {
    margin-left: auto;
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 0;
    }
  }

  &__nav {
    a {
      cursor: pointer;
      text-decoration: none;
      font-size: fontSize(title);
      line-height: 1.5;
      color: color(black);
      opacity: 1;
      transition: opacity 0.24s;
      &:hover {
        opacity: 0.7;
      }
    }
    & > a {
      margin-left: 32px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      display: none;
    }
  }

  &__link {
    padding: 8px 16px;
    margin-left: 0;
  }

  &__burger {
    display: none;
    background: color(gray-p);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-left: 12px;
    @media (max-width: getBreakpoint(tablet)) {
      display: flex;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 38px;
      height: 38px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 36px;
      height: 36px;
    }

    span {
      display: block;
      height: 2px;
      background: color(black);
      transition: background 0.24s;
      width: 22px;
      position: relative;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 18px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 16px;
      }

      &:before,
      &:after {
        content: "";
        left: 0;
        right: 0;
        height: 2px;
        background: color(black);
        position: absolute;
        transition: 0.24s;
        transition-property: transform, top, bottom, background;
      }

      &:before {
        top: -7px;
        @media (max-width: getBreakpoint(mobile-lg)) {
          top: -6px;
        }
      }

      &:after {
        bottom: -7px;
        @media (max-width: getBreakpoint(mobile-lg)) {
          bottom: -6px;
        }
      }
    }

    &--active {
      span {
        background: transparent;

        &:before {
          top: 0;
          transform: rotate(45deg);
        }

        &:after {
          bottom: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &:deep(.pn-header__drawer) {
    padding-top: 80px;
    padding-left: 16px;
    height: 100%;
    min-height: 100%;
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-top: 72px;
      padding-left: 8px;
    }

    a {
      cursor: pointer;
      text-decoration: none;
      font-size: fontSize(title);
      line-height: 1.5;
      color: color(black);
      opacity: 1;
      transition: opacity 0.24s;
      &:hover {
        opacity: 0.7;
      }

      &:not(.pn-header__link) {
        margin-top: 16px;
      }
    }
  }

  &__phone {
    background: var(--mainColor);
    color: var(--font2);
    border-radius: 8px;
    margin-left: 40px;
    padding: 10px 26px;
    &--mb {
      display: none;
    }
    &:deep(svg) {
      width: 24px;
      height: 24px;
    }
    &-wrap {
      width: 350px;
      padding: 32px 24px;
      border-radius: 8px;
      position: relative;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
      button {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
      }
      p {
        font-size: fontSize(headline-8);
        line-height: 1.4;
        margin-bottom: 12px;
        color: color(black);
        &:not(:first-child) {
          margin-top: 24px;
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          font-size: 17px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          font-size: fontSize(title);
          line-height: 1.5;
        }
      }
      a {
        font-size: fontSize(headline-5);
        line-height: 1.4;
        transition: color 0.24s;
        margin-right: auto;
        @media (max-width: getBreakpoint(mobile-lg)) {
          font-size: 23px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          font-size: fontSize(headline-6);
        }
        &:hover {
          color: color(primary);
        }
      }
      & > span {
        font-size: fontSize(label);
        @media (max-width: getBreakpoint(mobile-lg)) {
          font-size: 11px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          font-size: fontSize(small);
        }
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 100vw;
        border-radius: 12px 12px 0 0;
      }
    }
    &-mclose {
      position: absolute;
      right: 12px;
      top: 12px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      background: color(gray-darker);
      img {
        opacity: .7;
        transition: opacity .24s;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: 12px;
      border: none;
      &--pc {
        display: none;
      }
      &--mb {
        color: var(--font);
        display: flex;
        padding: 4px;
        width: 40px;
        height: 40px;
        .mask-icon {
          margin: 0;
          width: 32px;
          height: 32px;
          mask-size: 32px;
        }
      }
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 38px;
      height: 38px;
      &:deep(svg) {
        width: 28px;
        height: 28px;
      }
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 36px;
      height: 36px;
      &:deep(svg) {
        width: 26px;
        height: 26px;
      }
    }
  }
}
</style>
